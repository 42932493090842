/*
Created by: @themepixels
URL: http:
*/

label {
   font-weight: normal;
   margin-bottom: 5px !important;
   color: #4a535e;
}

code {
   -moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
   background: none;
   padding: 0;
}

small {
   font-size: 11px;
}

.lead {
   color: #333;
}

blockquote p {
   font-family: 'Georgia', sans-serif;
   font-weight: 100;
   color: #333;
}

blockquote .glyphicon,
blockquote .fa {
   float: left;
   margin-right: 10px;
   font-size: 20px;
}

h4, .h4 {
   line-height: 24px;
}

.row {
   margin-left: -10px;
   margin-right: -10px;
}

.col-xs-1, .col-xs-2,
.col-xs-3, .col-xs-4,
.col-xs-5, .col-xs-6,
.col-xs-7, .col-xs-8,
.col-xs-9, .col-xs-10,
.col-xs-11, .col-xs-12,
.col-sm-1, .col-sm-2,
.col-sm-3, .col-sm-4,
.col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8,
.col-sm-9, .col-sm-10,
.col-sm-11, .col-sm-12,
.col-md-1, .col-md-2,
.col-md-3, .col-md-4,
.col-md-5, .col-md-6,
.col-md-7, .col-md-8,
.col-md-9, .col-md-10,
.col-md-11, .col-md-12,
.col-lg-1, .col-lg-2,
.col-lg-3, .col-lg-4,
.col-lg-5, .col-lg-6,
.col-lg-7, .col-lg-8,
.col-lg-9, .col-lg-10,
.col-lg-11, .col-lg-12 {
   padding-left: 10px;
   padding-right: 10px;
}

.form-group::after {
   clear: both;
   display: block;
   content: '';
}

.form-bordered .form-group {
   margin: 0;
   padding: 15px 0;
   border-top: 1px solid #d3d7db;
}

.form-bordered .form-group:first-child {
   border-top: 0;
}

.form-striped .form-group {
   margin: 0;
   padding: 15px 0;
}

.form-striped .form-group:nth-child(even) {
   background: #fcfcfc;
}

.form-inline .form-group {
   margin-right: 20px;
}

.form-inline .checkbox {
   margin-right: 20px;
}

.form-control {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
   font-size: 13px;
   height: 36px;
}

.form-control:focus {
   border-color: #999;
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
   -moz-box-shadow: 3px 3px 0 rgba(12,12,12,0.05);
   -webkit-box-shadow: 3px 3px 0 rgba(12,12,12,0.05);
   box-shadow: 3px 3px 0 rgba(12,12,12,0.05);
}

.input-sm {
   font-size: 12px;
   padding: 7px 10px;
   height: auto;
}

.input-lg {
   font-size: 18px;
   padding: 12px 10px;
}

.btn {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
   line-height: 21px;
   -moz-transition: all 0.2s ease-out 0s;
	-webkit-transition: all 0.2s ease-out 0s;
	transition: all 0.2s ease-out 0s;
   padding: 6px 15px;
}

.btn:focus {
	outline: none;
}

.req-btn-sm {
  padding: 5px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-sm {
  padding: 5px 12px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xs {
   padding: 2px 10px;
}

.btn-lg {
   padding: 12px 20px;
}

.btn-default {
   background: #e4e7ea;
   color: #636e7b;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
   background: #f7f7f7;
   border-color: #ccc;
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
}

.btn-white {
   background: #fff;
   border: 1px solid #ccc;
   color: #666;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
   background: #f7f7f7;
}

.btn-default-alt {
   border: 2px solid #999;
   color: #999;
   text-transform: uppercase;
   font-family: 'LatoBold';
   font-size: 12px;
}

.btn-default-alt:hover,
.btn-default-alt:active,
.btn-default-alt:focus {
   border-color: #666;
   color: #666;
}

.btn-primary-alt {
   border: 2px solid #428BCA;
   color: #428BCA;
   text-transform: uppercase;
   font-family: 'LatoBold';
   font-size: 12px;
}

.btn-primary-alt:hover,
.btn-primary-alt:active,
.btn-primary-alt:focus {
   border-color: #357EBD;
   color: #357EBD;
}

.btn-success-alt {
   border: 2px solid #d94d00;
   color: #d94d00;
   text-transform: uppercase;
   font-family: 'LatoBold';
   font-size: 12px;
}

.btn-success-alt:hover,
.btn-success-alt:active,
.btn-success-alt:focus {
   border-color: #17a08c;
   color: #17a08c;
}

.btn-warning-alt {
   border: 2px solid #F0AD4E;
   color: #F0AD4E;
   text-transform: uppercase;
   font-family: 'LatoBold';
   font-size: 12px;
}

.btn-warning-alt:hover,
.btn-warning-alt:active,
.btn-warning-alt:focus {
   border-color: #EEA236;
   color: #EEA236;
}

.btn-danger-alt {
   border: 2px solid #D9534F;
   color: #D9534F;
   text-transform: uppercase;
   font-family: 'LatoBold';
   font-size: 12px;
}

.btn-danger-alt:hover,
.btn-danger-alt:active,
.btn-danger-alt:focus {
   border-color: #D43F3A;
   color: #D43F3A;
}

.btn-info-alt {
   border: 2px solid #5BC0DE;
   color: #5BC0DE;
   text-transform: uppercase;
   font-family: 'LatoBold';
   font-size: 12px;
}

.btn-info-alt:hover,
.btn-info-alt:active,
.btn-info-alt:focus {
   border-color: #46B8DA;
   color: #46B8DA;
}

.btn-brown {
   background-color: #583f26;
   border-color: #513921;
   color: #fff;
}

.btn-brown:hover,
.btn-browm:active,
.btn-brown:focus {
   color: #fff;
   background-color: #513921;
   border-color: #48321c;
}

.btn-brown-alt {
   border: 2px solid #583f26;
   color: #583f26;
   text-transform: uppercase;
   font-size: 12px;
   font-family: 'LatoBold';
}

.btn-brown-alt:hover,
.btn-brown-alt:active,
.btn-brown-alt:focus {
   border-color: #513921;
   color: #513921;
}

.btn-black {
   background: #333;
   color: #fff;
   border-color: #222;
}

.btn-black:hover,
.btn-black:active,
.btn-black:focus {
   background: #222;
   border-color: #111;
   color: #fff;
}

.btn-black-alt {
   border: 2px solid #333;
   color: #333;
   font-size: 12px;
   font-family: 'LatoBold';
   text-transform: uppercase;
}

.btn-black-alt:hover,
.btn-black-alt:active,
.btn-black-alt:focus {
   border: 2px solid #222;
   color: #222;
}

.btn-pink {
   background: #f579e8;
   border-color: #ec6bde;
   color: #fff;
}

.btn-pink:hover,
.btn-pink:active,
.btn-pink:focus {
   background: #ec6bde;
   border-color: #e261d4;
   color: #fff;
}

.btn-pink-alt {
   border: 2px solid #f579e8;
   color: #f579e8;
   font-size: 12px;
   font-family: 'LatoBold';
   text-transform: uppercase;
}

.btn-pink-alt:hover,
.btn-pink-alt:active,
.btn-pink-alt:focus {
   border-color: #ec6bde;
   color: #ec6bde;
}

.btn-darkblue {
   color: #fff;
   background: #2e4678;
   border-color: #29406f;
}

.btn-darkblue:hover,
.btn-darkblue:active,
.btn-darkblue:focus {
   background: #29406f;
   border-color: #243a66;
   color: #fff;
}

.btn-darkblue-alt {
   border: 2px solid #2e4678;
   color: #2e4678;
   text-transform: uppercase;
   font-size: 12px;
   font-family: 'LatoBold';
}

.btn-darkblue-alt:hover,
.btn-darkblue-alt:active,
.btn-darkblue-alt:focus {
   border-color: #29406f;
   color: #29406f;
}

.btn-orange {
   color: #fff;
   background: #f38108;
   border-color: #de7504;
}

.btn-orange:hover,
.btn-orange:active,
.btn-orange:focus {
   background: #de7504;
   border-color: #d67003;
   color: #fff;
}

.btn-orange-alt {
   border: 2px solid #f38108;
   color: #f38108;
   text-transform: uppercase;
   font-size: 12px;
   font-family: 'LatoBold';
}

.btn-orange-alt:hover,
.btn-orange-alt:active,
.btn-orange-alt:focus {
   border-color: #de7504;
   color: #de7504;
}

.btn-maroon {
   color: #fff;
   background: #990000;
   border-color: #880000;
}

.btn-maroon:hover,
.btn-maroon:active,
.btn-maroon:focus {
   background: #880000;
   border-color: #770000;
   color: #fff;
}

.btn-maroon-alt {
   border: 2px solid #990000;
   color: #990000;
   text-transform: uppercase;
   font-size: 12px;
   font-family: 'LatoBold';
}

.btn-maroon-alt:hover,
.btn-maroon-alt:active,
.btn-maroon-alt:focus {
   border-color: #880000;
   color: #880000;
}

.btn-lightblue {
   background: #0390fd;
   color: #fff;
   border-color: #028af3;
}

.btn-lightblue:hover,
.btn-lightblue:active,
.btn-lightblue:focus {
   background: #028af3;
   border-color: #0385ea;
   color: #fff;
}

.btn-lightblue-alt {
   border: 2px solid #0390fd;
   color: #0390fd;
   text-transform: uppercase;
   font-size: 12px;
   font-family: 'LatoBold';
}

.btn-lightblue-alt:hover,
.btn-lightblue-alt:active,
.btn-lightblue-alt:focus {
   border-color: #028af3;
   color: #028af3;
}

.btn-group.open .dropdown-toggle {
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
}

.badge {
   -moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
   font-weight: normal;
   font-size: 11px;
   vertical-align: middle;
   display: inline-block;
}

.badge-warning {
   background-color: #ec8c04;
}

.badge-success {
   background-color: #019a1e;
}

.nav-pills > li > a {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
}

.nav-tabs > li > a {
   -moz-border-radius: 3px 3px 0 0;
   -webkit-border-radius: 3px 3px 0 0;
   border-radius: 3px 3px 0 0;
}

.panel {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
   background: #fcfcfc;
   border: 0;
   -moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
   -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
   box-shadow: 0 3px 0 rgba(12,12,12,0.03);
}

.panel-heading,
.panel-footer {
   background: #fff;
   border-color: #eee;
}

.panel-heading {
   padding: 20px 5px;
}

.panel-default > .panel-heading {
   border-color: #e7e7e7;
   background-color: #fff;
   padding: 20px
}

.panel-heading-empty {
   background: none;
   border-bottom: 0;
}

.panel-heading p {
   font-size: 13px;
   margin-top: 10px;
   margin-bottom: 0;
   color: #77818e;
}

.panel-primary .panel-heading,
.panel-success .panel-heading,
.panel-warning .panel-heading,
.panel-danger .panel-heading,
.panel-info .panel-heading {
   border-bottom: 0;
}

.panel-success .panel-heading {
   background-color: #d94d00;
}

.panel-warning .panel-heading {
   background-color: #F0AD4E;
}

.panel-danger .panel-heading {
   background-color: #D9534F;
}

.panel-info .panel-heading {
   background-color: #5BC0DE;
}

.panel-dark .panel-heading {
   background-color: #1d2939;
}

.panel-btns a {
   margin-left: 8px;
   float: right;
   color: #000;
   display: inline-block;
   font-weight: bold;
   opacity: 0.4;
   font-size: 16px;
   -moz-transition: all 0.2s ease-out 0s;
	-webkit-transition: all 0.2s ease-out 0s;
	transition: all 0.2s ease-out 0s;
}

.panel-btns a.minimize {
   font-size: 18px;
}

.panel-btns a:hover {
   text-decoration: none;
   opacity: 0.5;
   cursor: pointer;
}

.panel-btns a.minimize:active,
.panel-btns a.minimize:focus {
   text-decoration: none;
}

.panel-title {
   font-size: 18px;
   color: #111;
   /*font-family: 'LatoBold'; */
}

.panel-title-alt {
   font-size: 13px;
   font-family: 'LatoBold';
   text-transform: uppercase;
   margin: 0;
}

.panel-title-alt + p {
   margin-top: 5px !important;
}

.panel-primary .panel-title,
.panel-success .panel-title,
.panel-warning .panel-title,
.panel-danger .panel-title,
.panel-info .panel-title,
.panel-dark .panel-title {
   color: #fff;
}

.panel-success .panel-btns a,
.panel-danger .panel-btns a,
.panel-dark .panel-btns a {
   color: #fff;
}

.panel-body-nopadding {
   padding: 0;
}

.panel-footer {
   padding: 20px;
}

.panel-footer:after {
   clear: both;
   display: block;
   content: '';
}

.panel-heading .nav-tabs {
   margin-bottom: -20px;
   border-bottom: 0;
}

.panel-heading .nav-tabs li {
   margin-right: 5px;
}

.panel-heading .nav-tabs li a {
   color: #666;
}

.panel-heading .nav-tabs li a:hover,
.panel-heading .nav-tabs li a:active,
.panel-heading .nav-tabs li a:focus {
   background-color: #fff;
   border-color: #fff;
   border-bottom: 0;
   color: #111;
}

.panel-heading .nav-tabs li.active a {
   background: #fcfcfc;
   color: #111;
}

.panel-heading .nav-tabs li.active a:hover,
.panel-heading .nav-tabs li.active a:active,
.panel-heading .nav-tabs li.active a:focus {
   background: #fcfcfc;
   color: #111;
   border: 1px solid #ddd;
   border-bottom-color: #fcfcfc;
}

.panel-heading .nav-justified {
   margin-bottom: -21px;
}

.panel-heading .nav-justified li a {
   border-bottom: 0;
}

.panel-alt .panel-heading {
   padding: 20px 20px;
}

.panel-alt .panel-title {
   font-size: 13px;
   text-transform: uppercase;
   font-family: 'LatoBold';
}

.panel-alt .panel-heading p {
   margin-top: 5px;
   line-height: 18px;
}

.panel-alt .panel-btns a {
   margin-top: -3px;
}

.panel-alt .panel-footer {
   padding: 15px;
}

.panel-alt .panel-body {
   padding: 15px;
}

.panel-primary .panel-heading p,
.panel-warning .panel-heading p,
.panel-success .panel-heading p,
.panel-danger .panel-heading p,
.panel-info .panel-heading p {
   color: #fff;
   opacity: 0.6;
}

.form-horizontal .control-label {
   font-weight: normal;
}

.has-success .form-control,
.has-warning .form-control,
.has-error .form-control {
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
}

.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus {
   -moz-box-shadow: 0px 0px 0 rgba(12,12,12,0.05);
   -webkit-box-shadow: 0px 0px 0 rgba(12,12,12,0.05);
   box-shadow: 0px 0px 0 rgba(12,12,12,0.05);
}


.input-group-addon {
   -moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
   font-size: 13px;
   background-color: #f7f7f7;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
   -moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
   height: 46px;
}

.input-group-btn .btn {
   line-height: 22px;
}

.dropdown-menu {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
   padding: 5px;
   -moz-box-shadow: 3px 3px 0 rgba(12,12,12,0.03);
   -webkit-box-shadow: 3px 3px 0 rgba(12,12,12,0.03);
   box-shadow: 3px 3px 0 rgba(12,12,12,0.03);
}

.dropdown-menu li a {
   -moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
   -moz-transition: all 0.2s ease-out 0s;
	-webkit-transition: all 0.2s ease-out 0s;
	transition: all 0.2s ease-out 0s;
}

.dropdown-menu > li > a {
   padding: 7px 10px;
   color: #666;
}

.dropdown-menu > li > a:hover {
   background-color: #e7e7e7;
   color: #333;
}

.tooltip-inner {
   -moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
}

.popover {
   -moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
   -moz-box-shadow: 1px 1px 2px rgba(0,0,0,0.05);
   -webkit-box-shadow: 1px 1px 2px rgba(0,0,0,0.05);
   box-shadow: 1px 1px 2px rgba(0,0,0,0.05);
   padding: 0;
}

.popover-title {
   -moz-border-radius: 2px 2px 0 0;
   -webkit-border-radius: 2px 2px 0 0;
   border-radius: 2px 2px 0 0;
   font-size: 13px;
   border-color: #ddd;
}

.modal-dialog {
   border: 0;
}

.modal-content {
   -moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
   -moz-box-shadow: 0 5px 0 rgba(0,0,0,0.4);;
   -webkit-box-shadow: 0 5px 0 rgba(0,0,0,0.4);;
   box-shadow: 0 5px 0 rgba(0,0,0,0.4);
   border: 0;
}

.modal-title {
   font-size: 20px;
   color: #333;
}

.modal-header .close {
   margin-top: 4px;
}

.modal-backdrop.in {
   opacity: 0.65;
}

.alert {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
}

.alert .close {
   font-size: 16px;
   margin-top: 3px;
}

.alert .alert-link {
   font-family: 'LatoBold';
   font-weight: normal;
}

.alert > p + p {
   margin-top: 15px;
}

.alert > p + p .btn {
   margin-right: 5px;
}

.label {
   -moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
   padding: 4px 8px;
   font-weight: normal;
   font-size: 11px;
}

.badge-primary {
   background-color: #428BCA;
}

.badge-warning {
   background-color: #F0AD4E;
}

.badge-success {
   background-color: #d94d00;
}

.badge-danger {
   background-color: #D9534F;
}

.badge-info {
   background-color: #5BC0DE;
}

.progress {
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
   height: 15px;
   margin-bottom: 15px;
}

.progress-bar {
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
}

.progress-bar-success {
   background-color: #d94d00;
}

.progress-sm {
	height: 10px;
	-moz-border-radius: 2px;
   -webkit-border-radius: 2px;
   border-radius: 2px;
}

.panel-group .panel-heading {
   padding: 0;
}

.panel-group .panel-title {
   font-size: 14px;
   font-family: 'LatoBold';
}

.panel-group .panel-title a {
   padding: 14px 15px;
   display: block;
}

.panel-group .panel-title a:hover,
.panel-group .panel-title a:focus,
.panel-group .panel-title a:active {
   text-decoration: none;
}

.panel-group .panel-title a.collapsed {
   background-color: #f7f7f7;
   color: #666;
	border-bottom: 1px solid #ddd;
}

.panel-group .panel:last-child .panel-title a {
	border-bottom: 0;
}

.panel-group .panel-title a.collapsed:hover {
   background-color: #f3f3f3;
}

.panel-collapse.in .panel-body {
	border-bottom: 1px solid #ddd;
}

.panel-group .panel + .panel {
   margin-top: 0;
   border-top: 0;
   -moz-border-radius: 0;
   -webkit-border-radius: 0;
   border-radius: 0;
}

.panel-group .panel:last-child {
   -moz-border-radius: 0 0 3px 3px;
   -webkit-border-radius: 0 0 3px 3px;
   border-radius: 0 0 3px 3px;
}

.panel-group .panel:first-child {
   -moz-border-radius: 3px 3px 0 0;
   -webkit-border-radius: 3px 3px 0 0;
   border-radius: 3px 3px 0 0;
}

.panel-group-dark .panel {
   border-bottom: 0;
}

.panel-group-dark .panel-title a.collapsed {
   background-color: #1d2939;
   color: #ccc;
   border-bottom: 1px solid #273548;
}

.panel-group-dark .panel-title a.collapsed:hover {
   background-color: #17222f;
   color: #fff;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
   border-bottom-left-radius: 3px;
   border-top-left-radius: 3px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
   border-bottom-right-radius: 3px;
   border-top-right-radius: 3px;
}

.pagination > li > a,
.pagination > li > span {
   color: #636e7b;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
   background-color: #e4e7ea;
}

.pagination-split li {
   margin-left: 5px;
   display: inline-block;
   float: left;
}

.pagination-split li:first-child {
   margin-left: 0;
}

.pagination-split li a {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
   background-color: #9b9a9a;
   border-color: #1c1b1a;

}

.pager li > a, .pager li > span {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
   color: #636e7b;
}

.well {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
   border: 0;
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
   background-color: #e4e7ea;
}

.thumbnail {
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   border-radius: 3px;
	padding: 5px;
}

.media {
   padding-bottom: 15px;
}

.media-heading {
   color: #333;
   font-size: 14px;
   font-family: 'LatoBold';
   margin: 0;
}

small.media-desc {
   line-height: 16px;
   font-size: 12px;
   display: block;
}

.table {
	background: #fcfcfc;
	-moz-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
   -webkit-box-shadow: 0 3px 0 rgba(12,12,12,0.03);
   box-shadow: 0 3px 0 rgba(12,12,12,0.03);
}

.table th {
	font-weight: normal;
	font-family: 'LatoBold';
}

.table thead > tr > th {
	border-color: #eee;
	background-color: #fff;
	padding-top: 12px;
	padding-bottom: 12px;
	color: #444;
}

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
	border-color: #eee;
}

.table-striped > tbody > tr:nth-child(2n+1) > td,
.table-striped > tbody > tr:nth-child(2n+1) > th {
	background-color: #f5f5f5;
}

.modal-footer {
   margin-top: 0;
}

.modal-content .tab-content {
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
   box-shadow: none;
}
